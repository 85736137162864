import { isEmpty } from 'lodash-es'
import Cookies from 'js-cookie'

import * as tools from '@/utils'
import router from '@/router'

const TokenKey = 'Admin-Token'
const BusinessTypeKey = 'business-type'
const PopOwnerCodeKey = 'pop_owner_code'
const UserNameKey = 'username'
const PassWordKey = 'password'
const BNameKey = 'BNAME'
const UNameKey = 'UNAME'
const USER_NAME = 'user_name'
const LOGOUTED = 'logouted'
const LECOOMIS_SESSION = 'lecoomis_session'
const API_KEY = 'api_key'
const PRODUCT_ID = 'product_id'
// 标记会话过期后，ajax响应拦截器处理600状态时标记处理调用跳转使用
const LECOOMIS_SESSION_EXPIRED = 'lecoomis_session_expired'
// 菜单sign标记
const MENU_SIGN = 'menu_sign'
// 存储菜单列表
const MENU_LIST = 'menu_list'

// token
const TOKEN_KEY = 'token'

// 向sessionStorage存储token
export function setSSToken(content: string) {
  return tools.setSessionStorage(TOKEN_KEY, content)
}
// 由sessionStorage获取token
export function getSSToken() {
  return tools.getSessionStorage(TOKEN_KEY)
}
// 向localStorage存储token
export function setLSToken(content: string) {
  return localStorage.setItem(TOKEN_KEY, content)
}
// 由localStorage获取token
export function getLSToken() {
  return localStorage.getItem(TOKEN_KEY)
}
// 由localStorage删除token
export function removeLSToken() {
  return localStorage.removeItem(TOKEN_KEY)
}

// token
export function setToken(token) {
  // console.log('At auth set-token', token)
  return Cookies.set(TokenKey, token)
}
export function getToken() {
  console.log('has token key', !isEmpty(Cookies.get(TokenKey)))
  return Cookies.get(TokenKey) ? Cookies.get(TokenKey) : ''
}
// API key
export function setAPIKey(apiKey) {
  // console.log('At auth set-apiKey', apiKey)
  return Cookies.set(API_KEY, apiKey)
}
export function getAPIKey() {
  /* console.log('get token key', Cookies.get(TokenKey)) */
  return Cookies.get(API_KEY) ? Cookies.get(API_KEY) : ''
}
export function removeAPIKey() {
  /* console.log('get token key', Cookies.get(TokenKey)) */
  return Cookies.remove(API_KEY)
}
// product_id
export function setProductID(productID) {
  // console.log('At auth set-productID', productID)
  return Cookies.set(PRODUCT_ID, productID)
}
export function getProductID() {
  /* console.log('get token key', Cookies.get(TokenKey)) */
  return Cookies.get(PRODUCT_ID) ? Cookies.get(PRODUCT_ID) : ''
}
export function removeProductID() {
  /* console.log('get token key', Cookies.get(TokenKey)) */
  return Cookies.remove(PRODUCT_ID)
}
// export function getLecoomisSessionKey() {
//   console.log('get lecoo mis session', Cookies.get(LecoomisSessionKey))
//   return Cookies.get(LecoomisSessionKey)
// }
export function removeToken() {
  return Cookies.remove(TokenKey)
}
// 设置会话过期计数
export function setLecoomisSeesionExpired(param) {
  // console.log('At auth set session expired', param)
  return Cookies.set(LECOOMIS_SESSION_EXPIRED, param)
}
// 获取会话过期计数
export function getLecoomisSeesionExpired() {
  // console.log('At auth get session expired')
  return Cookies.get(LECOOMIS_SESSION_EXPIRED) ? Cookies.get(LECOOMIS_SESSION_EXPIRED): undefined
}
export function removeLecoomisSeesionExpired() {
  // console.log('At auth remove session expired')
  Cookies.remove(LECOOMIS_SESSION_EXPIRED)
}

// business type
export function setBusinessType(token) {
  return Cookies.set(BusinessTypeKey, token)
}
export function getBusinessType() {
  /* console.log('get token key', Cookies.get(TokenKey)) */
  return Cookies.get(BusinessTypeKey)
}
export function removeBusinessType() {
  return Cookies.remove(BusinessTypeKey)
}
// 存入username
export function setUserName(username) {
  return Cookies.set(UserNameKey, username, { expires: 7, path: '/' })
}
// 存入username(api)
export function setNickName(username) {
  return Cookies.set(USER_NAME, username, { expires: 7, path: '/' })
}
export function setNickNameLS(username: string) {
  return localStorage.setItem('account', username)
}
export function removeNickName() {
  return Cookies.remove(USER_NAME)
}
// 删除username
export function removeUserName() {
  return Cookies.remove(UserNameKey, { path: '/' })
}
// 获取username
export function getUserName() {
  return Cookies.get(UserNameKey)
}

// 存入password
export function setPassWord(password) {
  return Cookies.set(PassWordKey, password, { expires: 7, path: '/' })
}
// 获取password
export function getPassWord() {
  return Cookies.get(PassWordKey)
}
// 删除password
export function removePassWord() {
  return Cookies.remove(PassWordKey, { path: '/' })
}
// 获取username
export function getCookieUserName() {
  return Cookies.get(UNameKey) ? Cookies.get(UNameKey) : 'empty'
}
export function removeCookieUserName() {
  console.log('remove cookie UNAME')
  return Cookies.remove(UNameKey, { path: '/' })
}
// get nick name
export function getNickName() {
  // return Cookies.get(USER_NAME) ? Cookies.get(USER_NAME) : 'empty'
  return Cookies.get(USER_NAME)
}
export function getNickNameLS() {
  // return Cookies.get(USER_NAME) ? Cookies.get(USER_NAME) : 'empty'
  return localStorage.getItem('account')
}

// 删除password
export function removeLecooMisSession() {
  console.log('remove cookies leccomis_session')
  return Cookies.remove(LECOOMIS_SESSION, { path: '/' })
}
// LOGOUTED
export function setLogouted(status: boolean) {
  return Cookies.set(LOGOUTED, status)
}
// 获取LOGOUTED
export function getLogouted() {
  return Cookies.get(LOGOUTED)
}
export function removeLogouted() {
  return Cookies.remove(LOGOUTED)
}
// 到登录页（需要手动清除token）
export function redirectToLogin() {
  console.log('auth redirect to login by 600')
  // router.beforeEach(async(to, from, next) => {
  //   console.log('permission router beforeEach')
  // })
  // if (window.location.hostname.indexOf('localhost') >= 0) {
  //   window.location.href = '/'
  // } else {
  //   window.location.href = 'https://' + window.location.hostname + '/admin/nx-admin/'
  // }
  // console.log('auth redirect-login', router.app._route)
  router.replace({ path: '/login' })
  /* 设置cookie标记登出状态，供页面激活时强制刷新使用 */
  setLogouted(true)
}
// 设置菜单缓存sign标识
export function setMenuSign(signVal) {
  console.log('set menu sign', signVal)
  if (signVal) {
    localStorage.setItem(MENU_SIGN, signVal)
  }
}
// 获取菜单缓存sign标识
export function getMenuSign() {
  if (localStorage.getItem(MENU_SIGN) !== '') {
    return localStorage.getItem(MENU_SIGN)
  }
  else {
    return ''
  }
}
// 移除菜单缓存sign标识
export function removeMenuSign() {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'menu_sign')) {
    if (localStorage.getItem(MENU_SIGN) !== '') {
      return localStorage.removeItem(MENU_SIGN)
    }
    else {
      return ''
    }
  }
  else {
    return ''
  }
}
// not used
// 设置菜单列表缓存
export function setLsMenuList(menuList) {
  /* console.log('set menu list at ls', menuList) */
  if (menuList) {
    localStorage.setItem(MENU_LIST, menuList)
  }
}
// 获取菜单列表缓存
export function getLsMenuList() {
  console.log('get ls menu list')
  if (localStorage.getItem(MENU_LIST).length !== 0) {
    return localStorage.getItem(MENU_LIST)
  }
  else {
    return []
  }
}
// 移除菜单标识
export function removeMenuList() {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'menu_list')) {
    if (localStorage.getItem(MENU_LIST).length !== 0) {
      return localStorage.removeItem(MENU_LIST)
    }
    else {
      return ''
    }
  }
  else {
    return ''
  }
}
