// import { resolve } from 'path-browserify'
// import { reject } from 'lodash-es'
import { isString } from 'lodash-es'
import useRouteStore from './route'
import useMenuStore from './menu'
import router from '@/router'
import apiUser from '@/api/modules/user'
import { postLoginReq } from '@/api/modules/commons'
import {
  misLogout
  // ,businessLoginReq
  // getInfo
} from '@/api/modules/user'
import {
  generatorFailtureTime
} from '@/utils'
import {
  // getToken,
  removeAPIKey,
  removeBusinessType,
  removeCookieUserName,
  removeLogouted,
  removeMenuSign,
  removeNickName,
  removeToken,
  // setLSToken,
  // getLSToken,
  removeLSToken,
  setBusinessType,
  setToken,
  // setLecoomisSeesionExpired,
  // getLecoomisSeesionExpired,
  removeLecooMisSession,
  // removeLecoomisSeesionExpired
  // getBusinessType,
} from '@/utils/auth'

// crypt
import { aesUtil } from '@/utils/encryptn'

interface Login {
  token: string
}

const useUserStore = defineStore(
  // 唯一ID
  'user',
  () => {
    const routeStore = useRouteStore()
    const menuStore = useMenuStore()

    const account = ref(localStorage.account ?? '')
    // console.log('user store account', account.value)
    // const token = ref(getToken() ?? '')
    const token = ref(localStorage.token ?? '')
    // console.log('user store token', token.value)
    // 登录用户业务类型，0 不属于任何端，1 平台，2 商户
    const businessType = ref(0)
    const failure_time = ref(localStorage.failure_time ?? '')
    const permissions = ref<string[]>([])
    /** @description 设置token循环计数 */
    const tokenLimit = ref(0)
    const avatar = ref('')
    const roles = ref<string[]>([])
    // 商户90码 900001
    const popOwnerCode = ref('')
    // 一商多店的商类型（中is_super是1为有超级权限）
    const popOwnerIsSuper = ref('0')
    // 商户超级管理员
    const popOwnerIsRoot = ref('0')
    const glboalAdminLogin = ref(false)
    /**
     * @description 是否登录 通过登录存储过期日期比较（后按照实际接口补充参数，例如：Admin-token）
     */
    const isLogin = computed(() => {
      let retn = false
      // console.log('token value', token)
      if (token.value) {
        /* if (new Date().getTime() < Number.parseInt(failure_time.value) * 1000) {
          retn = true
        } */
        retn = true
      }
      console.log('user store isLogin ', retn)
      return retn
    })

    // 获取我的权限
    async function getPermissions() {
      // 通过 mock 获取权限
      const res = await apiUser.permission()
      permissions.value = res.data.permissions
      console.log('generate routes at front getPermissions > ', permissions.value)
      return permissions.value
    }
    // 修改密码
    async function editPassword(data: {
      password: string
      newpassword: string
    }) {
      await apiUser.passwordEdit(data)
    }
    /**
     * @description 平台登录
     */
    async function adminLoginAction(data: any) {
      const { username, password, captcha, captchakey } = data
      return await new Promise((resolve, reject) => {
        postLoginReq({
          username: username.trim(),
          password: password.trim(),
          captcha,
          captchakey,
        }).then((response) => {
          console.log('Admin login response', response)
          const resToken = response.token
          // commit('SET_TOKEN', token)
          token.value = resToken
          // commit('SET_BUSINESS_TYPE', 1)
          businessType.value = 1
          // console.log('Admin login response token', resToken, 'token', token)
          account.value = username.trim()
          failure_time.value = generatorFailtureTime()
          localStorage.setItem('account', username.trim())
          localStorage.setItem('token', resToken)
          localStorage.setItem('failure_time', generatorFailtureTime().toString())
          setToken(resToken)
          /* router.addRoute({
            path: '/',
            component: () => import('@/layouts/NavMapBaseLayout.vue'),
            redirect: '/site-map',
            children: [
              {
                path: 'site-map',
                name: 'SiteMap',
                component: () => import('@/views/lingoPage/lingoPageIndex.vue'),
                meta: {
                  title: '站点地图',
                },
              },
            ],
          }) */
          removeLogouted()
          // setBusinessType(1)
          setBusinessType(aesUtil.encryptPlainText('1', 'secret 123'))
          resolve(response)
        }).catch((error) => {
          console.log('store user', error)
          reject(error)
        })
      }).catch(function(reason) {
        console.log('reason', reason)
      })
    }
    // 设置avatar
    async function setAvatar(params: string) {
      // console.log('set avatar at store', avatar)
      avatar.value = params
    }
    // 设置avatar
    async function setRole(params: any) {
      // console.log('set role at store', params)
      if (isString(params)) {
        roles.value.push(params)
      } else if (params.length === 0) {
        roles.value = []
      }
    }
    // 纯清除角色
    async function clearRole() {
      setRole([])
    }
    // 设置登录调用加载状态
    async function setLoginLoading(param: boolean) {
      glboalAdminLogin.value = param
    }
    // remove token
    async function resetToken(params: string) {
      return new Promise<void>(resolve => {
        // commit('SET_TOKEN', '')
        setToken('')
        // commit('SET_BUSINESS_TYPE', 0)
        setBusinessType('0')
        // commit('SET_ROLES', [])
        setRole([])
        removeToken()
        removeLSToken()
        resolve()
      }).catch(function(reason) {
        console.log('reason', reason)
      })
    }
    // 设置登录人员业务类型 1 平台， 2 商户
    // async function setBusinessType(params: number) {
    //   console.log('set business type at store', params)
    //   businessType.value = params
    // }
    /** @description store 设置token循环计数 */
    async function setTokenLimit(params: number) {
      // console.log('set token limit at store', params)
      tokenLimit.value = params
    }
    // 设置一商多店super（即为超级用户）
    async function setPoponwerIsSuper(param: string) {
      popOwnerIsSuper.value = param
    }
    // 设置商户超级管理员
    async function setPoponwerIsRoot(param: string) {
      popOwnerIsRoot.value = param
    }
    // 设置商户90码（只针对商户角色）
    async function setPopOwerCode(param: string) {
      popOwnerCode.value = param
    }
    /**
     * @description （原setBusinessType）设置登录人员业务类型 1 平台， 2 商户
     * @param param
     */
    async function setGlobalBusinessType(param: number) {
      businessType.value = param
    }
    /** @description store 设置token */
    /* async function setToken(params: string) {
      console.log('set token at store', params)
      token.value = params
    } */
    // 已接退出登录接口 manual: boolean = false
    async function lecooLogout() {
      console.log('lecoo logout at store. manual ')
      /* let expired = getLecoomisSeesionExpired()
      if (!!expired && expired.toString().indexOf('undefined') >= 0 || Number(expired) <= 0) {
        removeLecoomisSeesionExpired()
        router.push({path: '/'})
      } else { */
      return await new Promise((resolve, reject) => {
        misLogout({ token: token.value }).then((response) => {
          // commit('SET_TOKEN', '')
          clearStatus()
          // router.push({
          //   name: 'login',
          //   query: {
          //     ...(router.currentRoute.value.path !== '/' && router.currentRoute.value.name !== 'login' && { redirect }),
          //   },
          // })
          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          // dispatch('tagsView/delAllViews', null, { root: true })
          // router.push({
          //   name: 'login',
          //   // query: {
          //   //   ...(router.currentRoute.value.path !== '/' && router.currentRoute.value.name !== 'login' && { redirect }),
          //   // },
          // })
          // if (manual) {
          //   // window.location.href = '/'
          //   router.replace({
          //     name: 'login'
          //   })
          // }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      }).catch(function(reason) {
        console.log('reason', reason)
      })
      // }
    }
    function clearStatus() {
      setToken('')
      // commit('SET_BUSINESS_TYPE', 0)
      setBusinessType(0)
      // commit('SET_ROLES', [])
      clearRole()
      removeLecooMisSession()
      removeToken()
      removeLSToken()
      removeBusinessType()
      // resetRouter()
      removeCookieUserName()
      removeNickName()
      removeAPIKey()
      removeMenuSign()
      routeStore.removeRoutes()
      menuStore.setActived(0)
    }
    // 登录
    async function login(data: {
      account: string
      password: string
    }) {
      // 通过 mock 进行登录
      const res = await apiUser.login(data)
      localStorage.setItem('account', res.data.account)
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('failure_time', res.data.failure_time)
      account.value = res.data.account
      token.value = res.data.token
      failure_time.value = res.data.failure_time
    }
    // 登出
    async function logout(redirect = router.currentRoute.value.fullPath) {
      localStorage.removeItem('account')
      localStorage.removeItem('token')
      localStorage.removeItem('failure_time')
      account.value = ''
      token.value = ''
      failure_time.value = ''
      routeStore.removeRoutes()
      menuStore.setActived(0)
      router.push({
        name: 'login',
        query: {
          ...(router.currentRoute.value.path !== '/' && router.currentRoute.value.name !== 'login' && { redirect }),
        },
      })
    }

    return {
      avatar,
      account,
      token,
      permissions,
      tokenLimit,
      glboalAdminLogin,
      isLogin,
      popOwnerCode,
      popOwnerIsRoot,
      popOwnerIsSuper,
      businessType,
      login,
      logout,
      lecooLogout,
      getPermissions,
      editPassword,
      adminLoginAction,
      setTokenLimit,
      setLoginLoading,
      setAvatar,
      setGlobalBusinessType,
    }
  },
)

export default useUserStore
