// import & init vxe-table plugins

import type { App } from 'vue'

// import VxeUI from 'vxe-pc-ui'
// import 'vxe-pc-ui/lib/style.css'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

// '查看凭证'-'流程是否结束'的vxetable全局格式化
VXETable.formats.add('formatFlowStatus', {
  cellFormatMethod({ cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '否':
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>${ tmpCellValue }</span>`
          break
        case '是':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>${ tmpCellValue }</span>`
          break
        default:
          return '-'
      }
    } else {
      strResult += '-'
      return strResult
    }
    return strResult
  }
})
// '查看凭证'-'生成凭证'的vxetable全局格式化：fsd_id不为0且有值，例如“1234”，fsd_err_reason为空属于“已生成”否则为“已生成-失败” 20240419
VXETable.formats.add('formatFsdIdStatus', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      if (tmpCellValue === '0') {
        strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>未生成</span>`
      } else {
        if (row.fsd_err_reason === '') {
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已生成-成功</span>`
        } else {
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已生成-失败</span>`
        }
      }
    } else {
      strResult += '-'
      return strResult
    }
    return strResult
  }
})
// '查看凭证'-'推送凭证到NC'的vxetable全局格式化
VXETable.formats.add('formatPushNcStatus', {
  // 财务NC推送状态 1 未推送，2 已推送-全部成功，3已推送-部分成功/失败 4 已推送- 推送失败
  cellFormatMethod({ cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>未推送</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已推送-全部成功</span>`
          break
        case '3':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>已推送-部分成功/失败</span>`
          break
        case '4':
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已推送-推送失败</span>`
          break
        default:
          return '-'
      }
    } else {
      strResult += '-'
      return strResult
    }
    return strResult
  }
})
// '查看凭证'-'同步NC反馈'直接格式化数据为JSON，渲染成HTML
VXETable.formats.add('formatJSON', {
  // 财务NC推送状态 1 未推送，2 已推送-全部成功，3已推送-部分成功/失败 4 已推送- 推送失败
  cellFormatMethod({ cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    let strResult = ''
    if (cellValue) {
      strResult += JSON.stringify(JSON.parse(cellValue))
    } else {
      strResult += '-'
      return strResult
    }
    return strResult
  }
})
// '查看凭证'-'生成凭证反馈'的vxetable全局格式化
VXETable.formats.add('formatIsSettlementStatus', {
  // 是否生成凭证 ，1未生成，2已生成，3无需生成
  cellFormatMethod({ cellValue }, format?: string) {
    // console.log('format permission value', cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>${tmpCellValue}</span>`
    } else {
      strResult += ''
      return strResult
    }
    return strResult
  }
})
// '文件上传列表'-'任务列表'转换文件下载 20240425
VXETable.formats.add('formatFileLink', {
  // 财务NC推送状态 1 未推送，2 已推送-全部成功，3已推送-部分成功/失败 4 已推送- 推送失败
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    let strResult = ''
    if (cellValue) {
      strResult += `<a class="el-link el-link--primary is-underline" href="${window.location.hostname === 'localhost' ? 'https://mis.test.lecoosys.com' : 'https://' + window.location.hostname}${row.file_name}" target="_blank">${row.origin_file_name}</a>`
    } else {
      strResult += '-'
      return strResult
    }
    return strResult
  }
})
// '任务结果统计列表'-'上传结果'的vxetable全局格式化
VXETable.formats.add('formatUploadStatusForTaskResult', {
  // 默认为null， 是否上传文件，有值代表已上传，null代表未上传
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    let strResult = ''
    if (cellValue) {
      if (row.is_upload_bank_flow === '是') {
        strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已上传</span>`
      } else {
        strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>无需上传</span>`
      }
    } else {
      if (row.is_upload_bank_flow === '是') {
        strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>未上传</span>`
      } else {
        strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>无需上传</span>`
      }
    }
    return strResult
  }
})
// '任务结果统计列表'-'数据处理结果'的vxetable全局格式化
VXETable.formats.add('formatProcessStatusForTaskResult', {
  // 默认为null， 上传处理结果；2成功；3失败
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    let strResult = ''
    if (cellValue) {
      const tmpCellValue = cellValue.toString()
      if (row.is_upload_bank_flow === '是') {
        switch (tmpCellValue) {
          case '2':
            strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>成功</span>`
            break
          case '3':
            strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>失败</span>`
            break
          default:
            strResult += '-'
        }
      } else {
        strResult += `-`
      }
    } else {
      strResult += '-'
    }
    return strResult
  }
})
// '任务创建列表'-'生成凭证'格式化：is_fsd 是否生成凭证；0未生成；1待生成；2生成中；3已生成； 20240603
VXETable.formats.add('formatIsFsdStatus', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>未生成</span>`
          break
        case '1':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>生成中</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>生成中</span>`
          break
        case '3':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已生成</span>`
          break
        default:
          strResult += '-'
      }
    } else {
      strResult += '-'
      return strResult
    }
    return strResult
  }
})
// '任务创建列表'is_push_nc 是否推送nc；0未推送；1待推送；2推送中；3已推送；
VXETable.formats.add('formatIsPushNCStatus', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>未推送</span>`
          break
        case '1':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-info-circle-solid"></i>待推送</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>推送中</span>`
          break
        case '3':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已推送</span>`
          break
        default:
          strResult += '-'
      }
    } else {
      strResult += '-'
      return strResult
    }
    return strResult
  }
})
// 创建任务：计算状态；0 未计算；1 待计算；2 计算中；3 计算成功；4 计算失败
VXETable.formats.add('formatCalcStatus', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>未计算</span>`
          break
        case '1':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-info-circle-solid"></i>待计算</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>计算中</span>`
          break
        case '3':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>计算成功</span>`
          break
        case '4':
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>计算失败</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// '财务账套信息管理列表
// 状态'： 审批状态（1 启用,2 停用）
VXETable.formats.add('formatSingleEnableState', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>启用</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>停用</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// 优惠券
// '券规则列表'： 禁用状态 20240618 启用禁用,1启用,0禁用
VXETable.formats.add('formatPromotionVoucherDisableStatus', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>启用</span>`
          break
        case '1':
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已禁用</span>`
          break
        default:
          strResult += '-'
      }
    } else {
      strResult += '-'
      return strResult
    }
    return strResult
  }
})
// '券管理列表'： 审批状态 （0未审批，1已通过,2已驳回）
VXETable.formats.add('formatIsPromotionVoucherAuditState', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span class="lecoo-cell-status warning"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>未审批</span>`
          break
        case '1':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已通过</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已驳回</span>`
          break
        // case '3':
        //   strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>终止</span>`
        //   break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// '券管理列表'： 生效状态（1生效中,2已过期,3待生效, 4已终止）
VXETable.formats.add('formatIsPromotionVoucherAffectState', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        // case '0':
        //   strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已终止</span>`
        //   break
        case '1':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-check-circle-solid"></i>生效中</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已过期</span>`
          break
        case '3':
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>待生效</span>`
          break
        case '4':
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已终止</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// '券管理列表'： 是否可叠加-0 允许叠加,1不允许叠加
VXETable.formats.add('formatIsPromotionVoucherExclusive', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `允许叠加`
          break
        case '1':
          strResult += `不允许叠加`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// GTN后返资源
// 单台、非单台预算案
// '列表'： 审批状态 （ 0 待审批 1 审批中 2 已通过 3 已驳回 ）
VXETable.formats.add('formatSigleBudgetCaseAuditState', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span class="lecoo-cell-status warning"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>待审批</span>`
          break
        case '1':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>审批中</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已通过</span>`
          break
        case '3':
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已驳回</span>`
          break
        default:
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>——</span>`
      }
    } else {
      strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>——</span>`
      return strResult
    }
    return strResult
  }
})
// 数据状态 0未生效 3生效4作废
VXETable.formats.add('formatSigleBudgetCasePlanStatus', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span>未生效</span>`
          break
        // case '1':
        //   strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>审批中</span>`
        //   break
        // case '2':
        //   strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已驳回</span>`
        //   break
        case '3':
          strResult += `<span>生效</span>`
          break
        case '4':
          strResult += `<span>作废</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// '列表'： 预算案类型 1单台 2非单台 默认为1
VXETable.formats.add('formatSigleBudgetCasePrePlanType', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format single budget case pre-plan-type value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span>单台</span>`
          break
        case '2':
          strResult += `<span>非单台</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// '分配方式'： 1 按销额分配
VXETable.formats.add('formatSendType', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format single budget case pre-plan-type value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span>按销额分配</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// 是否冲抵费用（非单台预算案）
VXETable.formats.add('isFreeFee', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format single budget case pre-plan-type value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span>是</span>`
          break
        case '2':
          strResult += `<span>否</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// 是否到账
VXETable.formats.add('formatIsReceived', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format single budget case pre-plan-type value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span>是</span>`
          break
        case '2':
          strResult += `<span>否</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// 资源包再分配： 1 公共池 2 非公共池
VXETable.formats.add('formatResourcePackageGsWalletType', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format single budget case pre-plan-type value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span>公共池</span>`
          break
        case '2':
          strResult += `<span>非公共池</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// 单台、非单台结算案
// 列表： 审批状态 （ 0 待发起 1 审批中 2 已通过 3 已驳回 4 待审批 ）
VXETable.formats.add('formatSigleFinishCaseAuditState', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span class="lecoo-cell-status warning"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>待发起</span>`
          break
        case '1':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>审批中</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已通过</span>`
          break
        case '3':
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>已驳回</span>`
          break
        case '4':
          strResult += `<span class="lecoo-cell-status warning"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>待审批</span>`
          break
        default:
          strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>——</span>`
      }
    } else {
      strResult += `<span class="lecoo-cell-status default"><i class="icon iconfont text-mini icon-minus-circle-solid"></i>——</span>`
      return strResult
    }
    return strResult
  }
})
// 列表：计算状态 0待计算 1计算中 2已完成 3 失败
VXETable.formats.add('formatSigleSettlementtCaseCalcStatus', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format permission value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '0':
          strResult += `<span class="lecoo-cell-status warning"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>待计算</span>`
          break
        case '1':
          strResult += `<span class="lecoo-cell-status primary"><i class="icon iconfont text-mini icon-clock-circle-solid"></i>计算中</span>`
          break
        case '2':
          strResult += `<span class="lecoo-cell-status success"><i class="icon iconfont text-mini icon-check-circle-solid"></i>已完成</span>`
          break
        case '3':
          strResult += `<span class="lecoo-cell-status danger"><i class="icon iconfont text-mini icon-close-circle-solid"></i>失败</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// 待办列表：预算、结算案类型
VXETable.formats.add('formatPreAndFinishPlanType', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format single budget case pre-plan-type value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span>预算案</span>`
          break
        case '2':
          strResult += `<span>结算案</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// 待办列表：审批类型
VXETable.formats.add('formatUpcomingAuditType', {
  cellFormatMethod({ row, cellValue }, format?: string) {
    // console.log('format single budget case pre-plan-type value', row, cellValue)
    const tmpCellValue = cellValue.toString()
    let strResult = ''
    if (tmpCellValue) {
      switch (tmpCellValue) {
        case '1':
          strResult += `<span>新增</span>`
          break
        case '2':
          strResult += `<span>编辑</span>`
          break
        case '3':
          strResult += `<span>作废</span>`
          break
        default:
          strResult = '-'
      }
    } else {
      strResult = '-'
      return strResult
    }
    return strResult
  }
})
// 插件可以是一个带 install() 方法的对象，亦或直接是一个将被用作 install() 方法的函数。(https://cn.vuejs.org/api/application#app-use)
function install(app: App) {
  app.use(VXETable)
}

export default { install }

