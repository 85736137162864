import './utils/system.copyright'
import ElementPlus from './provider-element'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VXEUI from './provider-vxetable/vxe-pc-ui-index'
import VXETable from './provider-vxetable'
// custom rewrite vxe-table
import './assets/styles/vxeTable/index.scss'

import App from './App.vue'
import pinia from './store'
import router from './router'
import useSettingsStore from './store/modules/settings'

// custom svg 20231017
// import svgIconCustom from './components/SvgIconCustom/index.vue'

// 自定义指令
import directive from '@/utils/directive'

// 加载 svg 图标
import 'virtual:svg-icons-register'

// import '@/assets/styles/main.css'

// 全局样式
import '@/assets/styles/globals.scss'

// global filters
import * as filters from './filters'

// 加载 iconify 图标（element plus）
import { downloadAndInstall } from '@/iconify-ep'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// register global utility filters
// Object.keys(filters).forEach(key => {
//   app.filter(key, filters[key])
// })
// app.use(filters)
app.config.globalProperties.$filters = filters

app.use(ElementPlus)
app.use(VXEUI)
app.use(VXETable)
app.use(pinia)
app.use(router)
directive(app)
if (useSettingsStore().settings.app.iconifyOfflineUse) {
  downloadAndInstall()
}

app.mount('#app')
